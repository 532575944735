/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {Download} = _components;
  if (!Download) _missingMdxReference("Download", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "SaaS integrations can appear simple on the surface: write code that allows System A to connect with System B and transfer data between the systems. Done."), "\n", React.createElement(_components.p, null, "And that may be true for that first integration or so. After all, your dev team reviewed the requirements and said, \"Yes, we can do that.\" It's possible that building your second or third integration also went well."), "\n", React.createElement(_components.p, null, "But at some point, you, your dev team, and even your customers realize that the traditional, one-off approach you're using to build integrations won't work at scale. Your customers' need for integrations keeps growing, but your integration challenges are growing even faster."), "\n", React.createElement(_components.h2, {
    id: "why-do-you-need-to-scale-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-you-need-to-scale-integrations",
    "aria-label": "why do you need to scale integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do you need to scale integrations?"), "\n", React.createElement(_components.p, null, "You may have dozens of customers today, but you'll have hundreds or thousands next year. And, based on current trends, each customer will use more apps over time (one 2020 report notes that mid-market businesses use an average of 137 apps). So you'll need to create even more integrations to keep pace. One way or the other, you'll end up with many customers and many integrations."), "\n", React.createElement(_components.p, null, "Scaling to handle all those integrations for all those customers? That's where an ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), " (or embedded integration platform) can help and we'll use the rest of this post to examine how that works."), "\n", React.createElement(_components.p, null, "But first, let's look at some of the problems you can have when trying to scale integrations without an embedded iPaaS."), "\n", React.createElement(_components.h2, {
    id: "problems-that-arise-when-scaling-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#problems-that-arise-when-scaling-integrations",
    "aria-label": "problems that arise when scaling integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Problems that arise when scaling integrations"), "\n", React.createElement(_components.p, null, "As noted, scaling integrations brings its own challenges. Some are simple; some are not. But all of them must be addressed to scale your SaaS product integrations successfully."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "How do you scale integrations without scaling all the teams (engineering, onboarding, and support) that handle the integrations?"), " You can't afford to double, triple, or quadruple the size of your teams to address integrations. You've got to figure out how to do more with less and execute on X times the number of integrations you have now, but without increasing your staffing linearly."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "How do you ensure you don't accrue substantial tech debt when scaling integrations?"), " Let's say you've got plenty of dev resources and are building several integrations simultaneously. How are your teams attempting to future-proof those integrations? Or are they simply knocking out as much code as they can as quickly as they can to take care of customers? If that's the case, how much future time will be spent rebuilding/refactoring those integrations?"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "How do you ensure sufficient resources remain available for your core product?"), " While a single integration may not require many resources, building out a dozen of them changes the equation. How will you ensure that your devs can primarily focus on your core app and not end up buried in integration work?"), "\n"), "\n", React.createElement(_components.h2, {
    id: "4-areas-for-successful-integration-scaling",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-areas-for-successful-integration-scaling",
    "aria-label": "4 areas for successful integration scaling permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4 areas for successful integration scaling"), "\n", React.createElement(_components.p, null, "From working with our customers, which are B2B SaaS companies across a wide number of industry verticals, we've determined that scaling integrations comes down to these four areas:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Development"), "\n", React.createElement(_components.li, null, "Deployment"), "\n", React.createElement(_components.li, null, "Support"), "\n", React.createElement(_components.li, null, "Infrastructure"), "\n"), "\n", React.createElement(_components.p, null, "Let's look at each area and see how an embedded iPaaS helps your teams avoid common issues while successfully scaling your B2B SaaS integrations."), "\n", React.createElement(_components.h3, {
    id: "scaling-integration-development",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scaling-integration-development",
    "aria-label": "scaling integration development permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scaling integration development"), "\n", React.createElement(_components.p, null, "Most SaaS teams think of development first, if only because it's what comes up early in the process. If two devs can build two integrations apiece per year, what will it take to support 100 new integrations in a year? It's easy to see that adding 49 more devs is not a sustainable path."), "\n", React.createElement(_components.p, null, "Fortunately, an embedded iPaaS (embedded integration platform) includes a ", React.createElement(_components.a, {
    href: "/platform/integration-designer/"
  }, "low-code integration designer"), ", ", React.createElement(_components.a, {
    href: "/components/"
  }, "API connectors"), " (and other built-in integration components), the ability for devs to ", React.createElement(_components.a, {
    href: "/platform/built-in-a-way-devs-love/"
  }, "write any code needed"), " for niche or vertical scenarios, and dedicated ", React.createElement(_components.a, {
    href: "/solutions/offload-infrastructure-work/"
  }, "infrastructure"), "."), "\n", React.createElement(_components.p, null, "The low-code integration designer and existing API connectors mean you can shift much of the integration development onto non-devs. If you want to keep it where devs do the bulk of integration development, an embedded iPaaS will still expedite the integration process by ensuring that some portion of the integration (30%, 50%, 80%) is pre-built."), "\n", React.createElement(_components.p, null, "Your devs can also write custom API connectors to integrate with any application your customers use, no matter the industry. And, for scenarios outside the norm, allowing devs to write code ensures that your team never ends up short of the goal because of low-code designer limitations."), "\n", React.createElement(_components.h3, {
    id: "scaling-integration-deployment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scaling-integration-deployment",
    "aria-label": "scaling integration deployment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scaling integration deployment"), "\n", React.createElement(_components.p, null, "Once you've figured out how to scale the development of X integrations, the next step is determining how to deploy all those integrations efficiently to your customers."), "\n", React.createElement(_components.p, null, "An embedded iPaaS includes an ", React.createElement(_components.a, {
    href: "/platform/embedded-integration-marketplace/"
  }, "integration marketplace"), " and the ability to ", React.createElement(_components.a, {
    href: "/solutions/productize-your-integrations/"
  }, "productize your integrations"), ". In addition, with integrations being built and deployed much more quickly than with a traditional development approach, you can set up new customers with integrations as part of your core product onboarding process."), "\n", React.createElement(_components.p, null, "The integration marketplace allows you to make your integrations accessible to internal and customer users for listing, searching, and deployment. As such, the integration marketplace showcases your integrations as essential product functionality (instead of the behind-the-scenes role that integrations often have)."), "\n", React.createElement(_components.p, null, "To assist with scaling integration deployment, you can productize your integrations by specifying the configuration values/options that are unique per customer. These values often pertain to auth and endpoints but can include almost anything. This configurability means your team can build a single integration and then deploy it to dozens (or hundreds) of customers without deploying different code for each customer."), "\n", React.createElement(_components.h3, {
    id: "scaling-integration-support",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scaling-integration-support",
    "aria-label": "scaling integration support permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scaling integration support"), "\n", React.createElement(_components.p, null, "Having many integrations only helps you move your product forward if your support team isn't buried in trouble tickets."), "\n", React.createElement(_components.p, null, "An embedded iPaaS includes ", React.createElement(_components.a, {
    href: "/solutions/provide-better-support/"
  }, "logging and real-time monitoring and alerting"), ". These functions help you scale integration support internally and externally."), "\n", React.createElement(_components.p, null, "Internally, your customer-facing teams can stay abreast of everything: what integrations are deployed (and for which customers), the status of every integration, what alerts have been triggered, and much more."), "\n", React.createElement(_components.p, null, "Your team gains insights that can warn them of issues before they become critical, allowing them to provide proactive support for integrations."), "\n", React.createElement(_components.p, null, "Externally, your customers can have a similar level of insight, allowing them to see the logs, set up monitors, and get alerts on their integrations. In addition, they can update configurations and perform first-level troubleshooting when there are problems. As a result, your customers send fewer support issues your way."), "\n", React.createElement(_components.p, null, "Finally, by putting so much control into the hands of your support team and your customers, your devs only need to provide support for those issues that your support team and customers can't resolve themselves."), "\n", React.createElement(_components.h3, {
    id: "scaling-integration-infrastructure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scaling-integration-infrastructure",
    "aria-label": "scaling integration infrastructure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scaling integration infrastructure"), "\n", React.createElement(_components.p, null, "For B2B SaaS integrations, infrastructure resources are often poorly calculated or overlooked entirely. Yes, integrations may be relatively small, discrete applications, but deploy them to a few hundred customers and watch the I/O, CPU, and memory usage climb rapidly."), "\n", React.createElement(_components.p, null, "An embedded iPaaS includes a ", React.createElement(_components.a, {
    href: "/solutions/offload-infrastructure-work/"
  }, "cloud-based infrastructure"), " that scales horizontally to match the integration load. No matter how many integrations and customers you have using the platform, resources are continuously reallocated to ensure optimal performance."), "\n", React.createElement(_components.p, null, "Integration loads can be highly irregular. Based on the time of the day, they may drop to almost nothing but then jump from that to thousands of operations per second in a few minutes. An embedded iPaaS also includes capabilities to address this type of bursting behavior, ensuring that integrations have necessary resources despite load variability."), "\n", React.createElement(_components.p, null, "For those who build out their integration infrastructure, only to watch it fall over when all their customers run an integration simultaneously, using an embedded iPaaS with its secure, scalable infrastructure makes all the difference."), "\n", React.createElement(_components.h2, {
    id: "are-there-limits-to-scaling-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-there-limits-to-scaling-integrations",
    "aria-label": "are there limits to scaling integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are there limits to scaling integrations?"), "\n", React.createElement(_components.p, null, "Certainly. No system is without limits. But practically speaking, an embedded iPaaS can handle the massive numbers of integrations and customers that today's B2B SaaS systems require."), "\n", React.createElement(_components.p, null, "And by simplifying and streamlining the integration build, deployment, and support processes, an embedded iPaaS allows you to make the most of the dev and non-dev resources you already have. Everyone benefits when you can turn integrations around more quickly, configure them appropriately, deploy them to a broad range of customers, and keep them running around the clock."), "\n", React.createElement(_components.p, null, "Implementing an embedded iPaaS for your integrations allows you to not only scale on demand, but also to ensure that your integrations become first-class features of your product."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/request-a-demo/"
  }, "Request a demo"), " and we'll show you how our embedded iPaaS can support your integration scaling needs."), "\n", React.createElement(Download, {
    title: "Want to learn more about scaling integrations with an embedded iPaaS? ",
    copy: "Download our Embedded iPaaS Guide.",
    label: "Get my Copy",
    to: "/embedded-ipaas-guide/",
    image: "/images/cta-thumbnails/embedded-ipaas-guide-thumbnail.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
